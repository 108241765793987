import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';
import { LocalStorage } from '../modules/shared/models/localstorage';
import { LocalStorageService } from '../services/localstorage/localstorage.service';
import { LOGIN_ROUTE } from "../utils/constants";

export const authGuard: CanActivateFn = async (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const router = inject(Router);
  const localStorageService: LocalStorageService = inject(LocalStorageService);

  try {
    const user = await Auth.currentAuthenticatedUser();

    const groups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
    const hasAccess = groups.some((group: string) => group.includes('_ROLE_OPERATOR'));

    if (!hasAccess) {
      router.navigate([ 'no-access' ]);
      return false;
    }
    return true;
  } catch {
    if (_state) {
      localStorageService.set(LocalStorage.REDIRECT_URL, _state.url, true);
    }
    router.navigate([ LOGIN_ROUTE ]);
    return false;
  }
};
