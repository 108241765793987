import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorage } from './modules/shared/models/localstorage';
import { HeaderService } from './services/header-service/header.service';
import { LocalStorageService } from './services/localstorage/localstorage.service';
import { TenantService } from './services/tenant/tenant.service';

@Component({
  selector: 'to-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoginPage: boolean = false;

  private readonly headerService: HeaderService = inject(HeaderService);
  private readonly tenantService: TenantService = inject(TenantService);
  private readonly localStorageService: LocalStorageService = inject(LocalStorageService);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    const isDarkmodeEnabled = JSON.parse(this.localStorageService.get(LocalStorage.DARKMODE_ENABLED) || 'false');
    if (isDarkmodeEnabled) {
      document.getElementById('root-container')?.classList.add('-dark-mode');
    }

    // redirect user to previously entered url
    const redirectUrl = this.localStorageService.get(LocalStorage.REDIRECT_URL, true);
    if (redirectUrl) {
      this.router.navigate([redirectUrl]);
      this.localStorageService.delete(LocalStorage.REDIRECT_URL);
    }

    this.tenantService.init();
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url.startsWith('/auth');

        // header options are retrieved from route data
        // headerOptions should be of type HeaderOptions
        // missing headerOptions leads to all options being false, thus not visible
        let headerOptions = this.getCurrentRoute().snapshot.data['headerOptions'];
        this.headerService.setOptions(headerOptions);
      }
    });
  }

  private getCurrentRoute(): ActivatedRoute {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
