import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoAccessComponent } from './modules/no-access/no-access.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { LocalStorage } from './modules/shared/models/localstorage';
import { SharedModule } from './modules/shared/shared.module';
import { ThankYouComponent } from './modules/thank-you/thank-you.component';
import { jwtInterceptor } from './services/auth/jwt.interceptor';
import { LocalStorageService } from './services/localstorage/localstorage.service';

const LANGUAGES_SUPPORTED: string[] = ['en'];

// required for AOT compilation
/* istanbul ignore next */
export function HttpLoaderFactory(handler: HttpBackend): TranslateHttpLoader {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appInitializerFactory(
  translate: TranslateService,
  localStorageService: LocalStorageService,
  injector: Injector
): () => Promise<any> {
  /* istanbul ignore next */
  return () =>
    new Promise((resolve) => {
      injector.get(LOCATION_INITIALIZED, Promise.resolve(null)).then(() => {
        // add all supported languages
        translate.addLangs(LANGUAGES_SUPPORTED);

        // use first supported language by default (en)
        let language: string = LANGUAGES_SUPPORTED[0];

        // use saved language from localstorage if available
        const savedLang = localStorageService.get(LocalStorage.SELECTED_LANGUAGE);
        if (savedLang && LANGUAGES_SUPPORTED.includes(savedLang)) {
          language = savedLang;
        } else {
          // use browser language if available
          const browserLang = translate.getBrowserLang();
          if (browserLang && LANGUAGES_SUPPORTED.includes(browserLang)) {
            language = browserLang;
          }
        }

        // Add here additional supported languages e.g. en|de
        translate.use(language).subscribe({
          complete: () => {
            // TODO: init data
            resolve(null);
          },
        });
      });
    });
}

@NgModule({
  declarations: [AppComponent, ThankYouComponent, NotFoundComponent, NoAccessComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [
    provideHttpClient(withInterceptors([jwtInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocalStorageService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
