<div class="flex justify-center items-center h-full flex-col">
  <frok-icon class="info-icon" bosch="alert-info"></frok-icon>

  <div class="font-bold text-2xl mt-9 text-center">
    <div>
      {{ 'NOT_FOUND.title1' | translate }}
    </div>
    <div>
      {{ 'NOT_FOUND.title2' | translate }}
    </div>
  </div>
</div>
