import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { NoAccessComponent } from './modules/no-access/no-access.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { ThankYouComponent } from './modules/thank-you/thank-you.component';

const routes: Routes = [
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: {
      headerOptions: {
        showProfile: true,
      },
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'incident',
    data: {
      headerOptions: {
        showProfile: true,
        showTenantSelection: false,
      },
    },
    canActivate: [authGuard],
    loadChildren: () => import('./modules/incident/incident.module').then((m) => m.IncidentModule),
  },
  {
    path: 'vehicle',
    data: {
      headerOptions: {
        showProfile: true,
        showTenantSelection: false,
      },
    },
    canActivate: [authGuard],
    loadChildren: () => import('./modules/vehicle/vehicle.module').then((m) => m.VehicleModule),
  },
  {
    path: 'control-center',
    data: {
      headerOptions: {
        showProfile: true,
        showTenantSelection: true,
      },
    },
    canActivate: [authGuard],
    loadChildren: () => import('./modules/control-center/control-center.module').then((m) => m.ControlCenterModule),
  },
  {
    path: '**',
    redirectTo: 'control-center/vehicles',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
