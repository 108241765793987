import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { catchError, from, switchMap, throwError } from 'rxjs';

export const jwtInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  return from(Auth.currentSession()).pipe(
    switchMap((auth) => {
      const jwt = auth.getAccessToken().getJwtToken();

      const withAuthRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return next(withAuthRequest);
    }),
    catchError((err) => {
      if (err === 'No current user') {
        return next(request);
      }
      return throwError(() => err);
    })
  );
};
